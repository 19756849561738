import React from "react";
import { navigate } from "gatsby";
import Layout from "../../components/layout";
import { BACKEND_BASE_URL } from "../../constant/constants";
import {
  InstituteBSection,
  NewsEventSlider,
  Dropdown,
  Seo,
} from "../../components";

const InstituteInfo = ({
  selected_institute,
  institute_info,
  institute_index,
}) => {
  const onChange = e => {
    navigate("/institutions/" + e.target.value);
  };

  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Dropdown
              data={institute_index}
              selected={selected_institute}
              onChange={onChange}
            />
            <>
              <InstituteBSection data={institute_info} />
              <NewsEventSlider />
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

const InstitutionBySlug = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo
        title={serverData.name}
        description={"Institutions of CSI MKD, " + serverData.name}
      />
      <InstituteInfo
        selected_institute={serverData.slug}
        institute_info={serverData.institute_info}
        institute_index={serverData.institute_index}
      />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const institute_info_res = await fetch(
      BACKEND_BASE_URL + "institutes/" + context.params.slug
    );
    const institute_index_res = await fetch(BACKEND_BASE_URL + "institutes");
    if (!institute_info_res.ok) {
      throw new Error(`Response failed`);
    }
    if (!institute_index_res.ok) {
      throw new Error(`Response failed`);
    }
    var institute_index = await institute_index_res.json();
    var institute_type_name = "";
    institute_index.forEach((item, index) => {
      if (item.slug === context.params.slug) {
        institute_type_name = item.name;
      }
    });
    return {
      props: {
        slug: context.params.slug,
        name: institute_type_name,
        institute_info: await institute_info_res.json(),
        institute_index: institute_index,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default InstitutionBySlug;
